import { memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import RichText from '@components/richtext';
import { isEnterOrSpaceKey, openInNewTab } from '@utils/common';

const getCardClass = cards => {
  switch (cards) {
    case 4:
      return 'col-span-4';
    case 3:
      return 'col-span-3';
    case 2:
      return 'col-span-2';
    case 5:
      return 'col-span-5';
    default:
      return 'col-span-1';
  }
};

const getFiftyFiftyCardClass = cards => {
  if (cards === 4) {
    return 'col-span-2';
  }
  return 'col-span-1';
};

const getCardBody = ({
  cardContent,
  cardTextAlignment,
  handleCTAClick,
  rightSideImage,
  leftSideImage,
  bottomSideImage
}) => {
  const { cardTitle, cardDescription, cardLinkText } = cardContent;
  const cardBodyHolder = cx(
    {
      'px-10 sm:px-6 md:px-9 lg:px-10 py-10 last:pt-6':
        !rightSideImage && !leftSideImage
    },
    {
      'align-middle flex flex-col justify-center p-10 lg:p-10 md:p-7':
        leftSideImage || rightSideImage
    },
    {
      'overflow-auto sm:h-[250px]': bottomSideImage
    }
  );
  if (!(cardTitle?.trim()?.length > 0) && !cardLinkText && !cardDescription) {
    return null;
  }
  return (
    <div className={cardBodyHolder}>
      {cardTitle?.trim()?.length > 0 && (
        <h2 className='mb-0 text-3xl font-normal text-primary mt-6'>
          {cardTitle}
        </h2>
      )}
      {cardDescription ? (
        <div className='text-greish text-base group-hover:text-primary min-h-[40px]'>
          <RichText
            text={cardDescription}
            classNames={{
              paragraphCustomClasses: 'my-4'
            }}
            urlTarget='New Tab'
          />
        </div>
      ) : null}
      {cardLinkText && (
        <div className='flex pt-3'>
          <div
            onKeyDown={e => isEnterOrSpaceKey(e) && handleCTAClick()}
            className={cx(
              'text-black text-base  w-full items-center no-underline cursor-pointer group-hover:text-primary hover:text-primary sm:text-xs inline-flex lg:text-base',
              { 'justify-center': cardTextAlignment }
            )}
            aria-label={`${cardTitle}-${cardLinkText}`}
          >
            <span className='uppercase font-bold'>{cardLinkText}</span>
            <span className='inline-block float-right p-1 !pt-0 h-5 w-5'>
              <ChevronRightIcon className='h-5 w-5 align-baseline' />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
const Card = ({
  fields,
  normalRow,
  centeredRows,
  isFiftyFifty,
  cards,
  fullWidthCard,
  position,
  cardTextAlignment = false
}) => {
  const router = useRouter();
  const { heading, cta, paragraphText, image, icon, imagePosition } = fields;
  const cardContent = {
    cardIcon: image?.fields?.asset?.[0]?.fields?.file?.url,
    cardTitle: heading,
    cardDescription: paragraphText,
    cardLinkText: cta?.fields?.label,
    cardCTALink: cta?.fields?.url,
    urlType: cta?.fields?.urlType,
    mediaUrl: cta?.fields?.mediaUrl?.fields?.file?.url,
    urlOpenerType: cta?.fields?.openerType,
    altText: image?.fields?.asset?.[0]?.fields?.description
  };

  const { cardIcon, altText, cardCTALink, urlType, mediaUrl, urlOpenerType } =
    cardContent;

  const params = {};
  if (cardCTALink?.startsWith('https://')) {
    params.rel = 'noopener noreferrer';
  }
  const href = urlType === 'Media' ? `https:${mediaUrl}` : cardCTALink || '';

  const handleCTAClick = () => {
    if (href) {
      if (urlOpenerType == 'New Tab') {
        openInNewTab(href);
      } else {
        router.push(href);
      }
    }
  };
  const rightSideImage = imagePosition === 'Right';
  const leftSideImage = imagePosition === 'Left';
  const bottomSideImage = imagePosition === 'Bottom';

  const containerClass = cx(
    'shadow-card relative',
    {
      'cursor-pointer': href
    },
    {
      'grid-cols-1 h-full group': normalRow
    },
    { 'grid lg:grid-cols-2 md:grid-cols-2': rightSideImage },
    {
      'max-sm:w-72 w-80 m-3 group overflow-hidden rounded': centeredRows
    },
    {
      'grid lg:grid-cols-2 group grid-cols-1 grow items-stretch': leftSideImage
    }
  );

  const imageHolderClasses = cx(
    'img-container',
    {
      'h-auto grid col-span-1': bottomSideImage
    },
    {
      'grid order-first md:col-span-1 md:order-first': leftSideImage
    },
    {
      'grid order-first md:col-span-1 md:order-last': rightSideImage
    },
    {
      'w-full relative': !leftSideImage && !rightSideImage && !bottomSideImage
    }
  );

  const renderCardBody = getCardBody({
    cardContent,
    cardTextAlignment,
    handleCTAClick,
    leftSideImage,
    rightSideImage,
    bottomSideImage
  });

  const priority = position >= 0 && position < 2;

  const cardHaveIcon = cardIcon && icon && (
    <div className='px-10 sm:px-6 md:px-9 lg:px-10 py-10 first:pb-0'>
      <Image
        src={`https:${cardIcon}`}
        blurDataURL={`https:${cardIcon}?fm=avif&q=1`}
        placeholder='blur'
        priority={priority}
        className='w-12 h-12 text-black'
        quality={100}
        alt={altText}
        height='50'
        width='50'
      />
    </div>
  );

  const cardImage =
    cardHaveIcon ||
    (cardIcon && (
      <div className={imageHolderClasses}>
        <Image
          src={`https:${cardIcon}`}
          blurDataURL={`https:${cardIcon}?fm=avif&q=1`}
          placeholder='blur'
          priority={priority}
          objectFit='cover'
          quality={100}
          height={100}
          width={100}
          objectPosition='50% 50%'
          layout='responsive'
          alt={altText}
        />
      </div>
    ));

  let holderClass;
  if (normalRow) {
    holderClass = cx(
      {
        'text-center': cardTextAlignment
      },
      isFiftyFifty && getFiftyFiftyCardClass(cards),
      fullWidthCard && getCardClass(cards)
    );
  } else if (centeredRows) {
    holderClass = cx('flex');
  }
  return (
    <div className={holderClass}>
      <div
        className={containerClass}
        initial='closed'
        whileInView='open'
        viewport={{ once: true, amount: 0.2 }}
        onClick={handleCTAClick}
        onKeyDown={e => isEnterOrSpaceKey(e) && handleCTAClick()}
      >
        {rightSideImage || bottomSideImage ? (
          <>
            {renderCardBody}
            {cardImage}
          </>
        ) : (
          <>
            {cardImage}
            {renderCardBody}
          </>
        )}
      </div>
    </div>
  );
};

export default memo(Card);
