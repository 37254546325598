import { memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import cx from 'classnames';
import Button from '@components/button';
import RichText from '@components/richtext';
import { getOpenerType } from '@utils/common';

const CardV2 = ({ cardData, className, position }) => {
  const cardIcon = cardData?.fields?.image?.fields?.asset[0].fields?.file?.url;

  const priority = position >= 0 && position < 2;

  return (
    <div className={cx('group', 'rounded', className)}>
      <div className='img-container'>
        {cardIcon ? (
          <Image
            src={`https:${cardIcon}`}
            blurDataURL={`https:${cardIcon}?fm=avif&q=1`}
            placeholder='blur'
            priority={priority}
            layout='responsive'
            quality={100}
            height={50}
            width={50}
            alt=''
          />
        ) : null}
      </div>
      <div className='pt-6'>
        <h2 className='mt-2 mb-4 text-3xl font-light'>
          {cardData?.fields?.headingText}
        </h2>
        <RichText text={cardData?.fields?.informationSection} />
        {cardData?.fields?.cta?.length > 0 ? (
          <div role='list' className='grid grid-cols-1 gap-4 lg:grid-cols-3'>
            {cardData?.fields?.cta?.map(ctaButton =>
              ctaButton?.fields?.visible ? (
                <div role='listitem' key={ctaButton?.fields?.label}>
                  <Link href={ctaButton?.fields?.url} passHref legacyBehavior>
                    <a
                      target={getOpenerType(ctaButton?.fields?.openerType)}
                      rel='noopener noreferrer'
                    >
                      {ctaButton?.fields?.type === 'Button' ? (
                        <Button
                          variant={
                            ctaButton?.fields?.brandColor === 'Blue'
                              ? 'primary'
                              : 'secondary'
                          }
                          key={ctaButton?.fields?.label}
                          aria-label={`${ctaButton?.fields?.ariaLabel}`}
                          className={`w-full mt-0 mb-0 sm:mt-0 lg:mt-4 lg:px-5 ${
                            ctaButton?.fields?.brandColor === 'Black'
                              ? 'bg-black text-white'
                              : ''
                          }`}
                        >
                          {ctaButton?.fields?.label}
                        </Button>
                      ) : (
                        ctaButton?.fields?.label
                      )}
                    </a>
                  </Link>
                </div>
              ) : null
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default memo(CardV2);
