import cx from 'classnames';
import PropTypes from 'prop-types';

export default function Loader({
  colorClass = 'fill-primary',
  bgColorClass = 'text-white-600',
  className = '',
  variant
}) {
  const classNames = cx(
    'inline',
    'ml-2',
    'w-5',
    'h-5',
    '-mb-1',
    'animate-spin',
    bgColorClass,
    colorClass,
    className
  );

  if (variant === 'page-spinner') {
    return (
      <div
        className={cx('flex my-auto mx-auto ', className)}
        data-testid='loader'
      >
        <svg
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          x='0px'
          y='0px'
          viewBox='0 0 81 86'
        >
          <circle className='bg-circle' cx='40.5' cy='43' r='20' />
          <path
            className='bg-track-circle loader-animation'
            d='M40.5,56.4c-7.4,0-13.4-6-13.4-13.4s6-13.4,13.4-13.4s13.4,6,13.4,13.4S47.9,56.4,40.5,56.4z M40.5,27
	c-8.8,0-16,7.2-16,16s7.2,16,16,16s16-7.2,16-16S49.3,27,40.5,27z'
          />
          <path
            className='white-circle-track loader-animation'
            d='M40.4,29.6c7.5,0,13.6,6.1,13.6,13.6h2.6c0-8.9-7.2-16.1-16.1-16.1V29.6z'
          />
        </svg>
      </div>
    );
  }

  return (
    <svg
      role='status'
      className={classNames}
      viewBox='-25 -25 100 100'
      preserveAspectRatio
      data-testid='loader'
    >
      <circle className='bg-circle' cx='40.5' cy='43' r='20' />
      <path
        className='bg-track-circle loader-animation'
        d='M40.5,56.4c-7.4,0-13.4-6-13.4-13.4s6-13.4,13.4-13.4s13.4,6,13.4,13.4S47.9,56.4,40.5,56.4z M40.5,27
	c-8.8,0-16,7.2-16,16s7.2,16,16,16s16-7.2,16-16S49.3,27,40.5,27z'
      />
      <path
        className='white-circle-track loader-animation'
        d='M40.4,29.6c7.5,0,13.6,6.1,13.6,13.6h2.6c0-8.9-7.2-16.1-16.1-16.1V29.6z'
      />
    </svg>
  );
}

Loader.PropTypes = {
  colorClass: PropTypes.string,
  bgColorClass: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string
};
