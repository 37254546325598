import { forwardRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const Radio = forwardRef(
  ({ name, label: radioLabel, classNames = {}, id, ...props }, ref) => {
    const inputClasses =
      'w-5 h-5 m-0 p-0 text-blue-600 bg-gray-100 focus:ring-blue-500 cursor-pointer';

    const labelClasses =
      'm-0 pl-2 text-sm font-medium text-gray-900 cursor-pointer';

    return (
      <span
        className={cx('flex items-center', classNames?.holder)}
        data-testid='Radio'
      >
        <input
          name={name}
          id={id}
          type='radio'
          className={cx(classNames?.input, inputClasses)}
          {...props}
          ref={ref}
        />
        <label htmlFor={id} className={cx(classNames?.label, labelClasses)}>
          {radioLabel}
        </label>
      </span>
    );
  }
);

Radio.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  classNames: PropTypes.any,
  id: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.any
};

Radio.displayName = 'Radio';

export default Radio;
