import { memo, useState } from 'react';
import ReactTabs from '@components/reactTabs';

const MAX_NOTIFICATION_ITEMS_PER_TAB = 6;

const ReactTabsWarpper = ({ tabs = [] }) => {
  const initialTabPanels = [];

  tabs.forEach(tabPanel => {
    const newTabPanel = {
      title: tabPanel?.fields?.title,
      accordion:
        tabPanel.fields?.accordion?.length > 0
          ? tabPanel.fields.accordion.slice(0, MAX_NOTIFICATION_ITEMS_PER_TAB)
          : []
    };

    newTabPanel.showViewMore =
      tabPanel.fields?.accordion?.length > 0 &&
      newTabPanel.accordion.length !== tabPanel.fields.accordion?.length;
    initialTabPanels.push(newTabPanel);
  });

  const [tabPanels, setTabPanels] = useState(initialTabPanels);

  const updateTabPanelData = loadMoreIndex => {
    // update tab panels data here
    const newTabPanels = [];
    tabs.forEach((tabPanel, tabIndex) => {
      if (tabIndex === loadMoreIndex) {
        const newTabPanel = {
          title: tabPanel?.fields?.title,
          accordion:
            tabPanel.fields?.accordion?.length > 0
              ? tabPanel.fields.accordion.slice(
                  0,
                  tabPanels[loadMoreIndex].accordion.length +
                    MAX_NOTIFICATION_ITEMS_PER_TAB
                )
              : []
        };
        newTabPanel.showViewMore =
          newTabPanel.accordion.length !== tabPanel.fields?.accordion?.length;

        newTabPanels.push(newTabPanel);
      } else {
        const newTabPanel = {
          title: tabPanel?.fields?.title,
          accordion:
            tabPanel.fields?.accordion?.length > 0
              ? tabPanel.fields.accordion.slice(
                  0,
                  MAX_NOTIFICATION_ITEMS_PER_TAB
                )
              : []
        };
        newTabPanel.showViewMore =
          newTabPanel.accordion.length !== tabPanel.fields?.accordion?.length;

        newTabPanels.push(newTabPanel);
      }
    });
    setTabPanels(newTabPanels);
  };

  return (
    <section data-testid='reactTabsWrapper' className='he-tab-section-wrapper'>
      <ReactTabs
        tabPanels={tabPanels}
        tabNames={initialTabPanels.map(tab => tab.title)}
        onLoadMore={updateTabPanelData}
      />
    </section>
  );
};

export default memo(ReactTabsWarpper);
