import { useState } from 'react';
import Image from 'next/image';
import cvx from 'classnames';
import { getBgColorClasses } from '@utils/common';

const VideoPlayer = ({
  videoUrl,
  heroImage,
  heroImagePosition,
  autoplay = true,
  altText = '',
  backgroundColor,
  height = ''
}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  const videoAttributes = {};
  if (videoUrl && heroImage) {
    videoAttributes.poster = `https:${heroImage}${
      videoUrl ? '?fm=avif&q=1' : ''
    }`;
  }

  const tryVideoPlay = timer => {
    setTimeout(() => {
      if (videoUrl && autoplay && !isVideoLoaded) {
        const videos = document.getElementsByClassName('hero-video');
        for (let videoIndex = 0; videoIndex < videos.length; videoIndex++) {
          if (videos[videoIndex].autoplay && videos[videoIndex].paused) {
            videos[videoIndex].play();
          }
        }
      }
    }, timer);
  };

  if (typeof window !== 'undefined' && videoUrl && autoplay) {
    // try to play video twice
    tryVideoPlay(5000);
    tryVideoPlay(10000);
  }

  return (
    <div
      className={cvx(
        'bg-cover bg-center bg-no-repeat flex items-center relative w-full',
        height,
        {
          flex: heroImagePosition !== 'Background',
          'flex-row-reverse': heroImagePosition === 'Left'
        },
        getBgColorClasses(backgroundColor)
      )}
    >
      {videoUrl && autoplay && (
        <video
          autoPlay={autoplay}
          onLoadedMetadata={onLoadedData}
          onLoadedData={onLoadedData}
          onPlaying={onLoadedData}
          muted
          loop
          playsInline
          controls={false}
          preload='metadata'
          // eslint-disable-next-line react/no-unknown-property
          webkit-playsinline='true'
          className={cvx('object-cover w-full relative hero-video', height)}
          {...videoAttributes}
        >
          <source src={`https:${videoUrl}`}></source>
        </video>
      )}

      {!isVideoLoaded && heroImage && (
        <Image
          src={`https:${heroImage}`}
          layout='fill'
          quality={100}
          priority={true}
          className={cvx('object-cover w-full relative', height)}
          alt={altText}
        />
      )}
    </div>
  );
};
export default VideoPlayer;
