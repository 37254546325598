import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Image from 'next/image';
import cvx from 'classnames';
import Cta from '@components/cta';
import HeadingWithSubText from '@components/headingWithSubText';
import RichText from '@components/richtext';

const CarouselComponent = ({
  carouselItems,
  classes = {},
  centeredModel = false
}) => {
  return (
    <>
      <Carousel
        emulateTouch={true}
        stopOnHover={false}
        showThumbs={false}
        showArrows={true}
        centerMode={centeredModel}
        centerSlidePercentage={'80'}
        infiniteLoop={true}
        autoPlay={true}
        ariaLabel={'Home page slider'}
        swipeable={true}
        showStatus={false}
        interval={8000}
        className={classes?.carousel}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type='button'
              onClick={onClickHandler}
              title={label}
              className='dp-left-arrow'
            >
              <FaChevronLeft
                height={centeredModel ? 40 : 80}
                size={50}
                className='align-middle cursor-pointer text-white'
              />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type='button'
              onClick={onClickHandler}
              title={label}
              className='dp-right-arrow'
            >
              <FaChevronRight
                height={centeredModel ? 40 : 80}
                size={50}
                className='align-middle cursor-pointer text-white'
              />
            </button>
          )
        }
      >
        {centeredModel
          ? carouselItems?.map(carouselItem => (
              <div className='p-2' key={carouselItem.sys.id}>
                <div className='bg-white p-10 w-full text-left min-h-[350px] sm:min-h-[380px] md:min-h-[380px] lg:min-h-[330px] xl:min-h-[290px]'>
                  <div className='text-2xl text-black md:text-3xl'>
                    {carouselItem?.fields?.headline}
                  </div>
                  <div className='min-h-[78px] text-sm text-[#505055] md:min-h-[60px]'>
                    {
                      <RichText
                        paragraphCustomClasses='dp-truncate-line-clamp'
                        text={carouselItem?.fields?.paragraphText}
                      />
                    }
                  </div>
                  <div role='list' className='flex pt-6'>
                    <Cta ctaArray={carouselItem?.fields?.cta} />
                  </div>
                </div>
              </div>
            ))
          : carouselItems?.map(carouselItem => {
              const item = carouselItem?.fields?.headingWithSubtext;
              const sectionType = item?.sys?.contentType?.sys?.id;
              const image =
                carouselItem?.fields?.image?.fields?.asset?.[0]?.fields?.file
                  ?.url;
              const altText = image && carouselItem.fields.image.fields.altText;
              const opacityRange = carouselItem?.fields?.darkeningLayerOpacity;

              return (
                <div
                  key={carouselItem.sys.id}
                  className={cvx(
                    ' top-0 left-0 right-0 !py-0 !mx-0 ',
                    classes?.height
                  )}
                >
                  {sectionType === 'headingWithSubText' &&
                    Object.values(item.fields).length && (
                      <div className='hidden lg:block '>
                        <HeadingWithSubText
                          contentWidth='lg:w-2/3'
                          key={item.sys.id}
                          fields={item.fields}
                          classes={{
                            sectionClasses:
                              'h-full z-10 absolute px-20 lg:px-28 lg:w-full',
                            paragraphCustomClasses: 'my-8',
                            containerClasses: 'lg:w-3/5'
                          }}
                        />
                      </div>
                    )}
                  {image && (
                    <>
                      <div
                        className={cvx(
                          '!h-[280px] top-0 left-0 right-0 z-0 lg:!h-full',
                          classes?.height
                        )}
                      >
                        <Image
                          src={`https:${image}`}
                          alt={altText}
                          blurDataURL={`https:${image}?fm=avif&q=1`}
                          placeholder='blur'
                          quality={100}
                          objectFit='cover'
                          layout='fill'
                        />
                      </div>
                      {sectionType === 'headingWithSubText' &&
                        Object.values(item?.fields).length && (
                          <div className='relative block float-left !h-auto  !text-white  !bg-[#000] w-full text-left lg:hidden'>
                            <HeadingWithSubText
                              contentWidth='lg:w-2/3'
                              key={item.sys.id}
                              fields={item.fields}
                              classes={{
                                sectionClasses:
                                  'z-10 !text-2xl float-left !text-white lg:mx-28 lg:absolute',
                                headingClasses: '!text-white text-2xl',
                                paragraphCustomClasses:
                                  'my-0 !text-white text-sm'
                              }}
                            />
                          </div>
                        )}
                    </>
                  )}
                  <div
                    style={{
                      backgroundColor: `rgba(0, 0, 0, ${opacityRange})`,
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0
                    }}
                    className='hidden lg:block'
                  ></div>
                </div>
              );
            })}
      </Carousel>
    </>
  );
};

export default CarouselComponent;
