import { toast } from 'react-toastify';
import { findADealer } from '@services/findADealer';

export const findADealerAction = async params => {
  try {
    return await findADealer(params);
  } catch (error) {
    if (error.message) {
      toast.error(error.message);
    }
    console.error(error);
  }
};
