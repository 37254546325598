import React, { Fragment, memo, useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import contentTypeRenderer from '@components/contentTypeRenderer';
import {
  SIDEBYSIDEVARIANTS,
  PageContentTypes,
  LEFT,
  RIGHT
} from '@constants/index';

/**
 * @class
 * @param {Object} sectionData Fields Data for section elements
 *
 * @returns Side by Side section Component as per the section data
 */

const SideBySideContent = ({ sectionData, priority, customCSS = {} }) => {
  const {
    variant,
    backgroundColor,
    textColor,
    leftContent,
    rightContent,
    defaultPadding,
    internalTitle,
    floatingImage = false
  } = sectionData;
  const leftContentType = leftContent?.[0]?.sys?.contentType?.sys.id;
  const rightContentType = rightContent?.[0]?.sys?.contentType?.sys.id;
  const { FIFTYFIFTY, SEVENTYTHIRTY, THIRTYSEVENTY } = SIDEBYSIDEVARIANTS;
  const { Mediaitem, HeadingwithSubText, InformationModuleSection, Form } =
    PageContentTypes;

  const sectionClasses = cx(
    'px-7',
    'border-b-0',
    '-mb-px',
    {
      '!py-0 mb-10': floatingImage
    },
    {
      'px-12 py-10 lg:px-12 py-10': defaultPadding,
      'absolute !px-0 !py-0 md:relative': !defaultPadding,
      '!px-0 !py-0 relative': !defaultPadding && FIFTYFIFTY
    },
    {
      '!px-0':
        variant === SIDEBYSIDEVARIANTS.FIFTYFIFTY &&
        leftContentType === Mediaitem &&
        !defaultPadding
    },
    {
      'bg-black': backgroundColor === 'Black',
      'text-white': textColor === 'White'
    },
    {
      'bg-white': backgroundColor === 'White',
      'text-black': textColor === 'Black'
    }
  );

  SideBySideContent.PropTypes = {
    sectionData: PropTypes.shape({
      variant: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      leftContent: PropTypes.array.isRequired,
      rightContent: PropTypes.array.isRequired,
      defaultPadding: PropTypes.bool.isRequired,
      internalTitle: PropTypes.string.isRequired,
      floatingImage: PropTypes.bool
    }).isRequired,
    priority: PropTypes.string.isRequired,
    customCSS: PropTypes.object.isRequired
  };

  const headingMediaContainerClasses = useCallback(
    contentType => {
      return cx(
        {
          'order-last lg:order-none': contentType === Mediaitem
        },
        {
          'order-first lg:order-none':
            contentType === HeadingwithSubText ||
            contentType === InformationModuleSection
        }
      );
    },
    [HeadingwithSubText, InformationModuleSection, Mediaitem]
  );

  const SeventyThirtyContent = useCallback(
    ({ isSeventy = true }) => (
      <div
        className={` grid-cols-1 lg:grid-cols-5 justify-items-start lg:grid`}
      >
        <div
          className={cx(
            { 'grid col-span-3': isSeventy },
            {
              'grid col-span-2': !isSeventy
            },
            headingMediaContainerClasses(leftContentType)
          )}
        >
          {leftContent?.map(contentItem => (
            <Fragment key={contentItem?.sys?.contentType?.sys?.id}>
              {contentTypeRenderer(
                contentItem?.sys?.contentType?.sys?.id,
                contentItem?.fields,
                LEFT,
                priority,
                floatingImage
              )}
            </Fragment>
          ))}
        </div>
        <div
          className={cx(
            'grow items-stretch',
            { 'grid col-span-2': isSeventy },
            {
              'grid col-span-3': !isSeventy
            },
            headingMediaContainerClasses(rightContentType)
          )}
        >
          {rightContent?.map(contentItem => (
            <Fragment key={contentItem?.sys?.contentType?.sys?.id}>
              {contentTypeRenderer(
                contentItem?.sys?.contentType?.sys?.id,
                contentItem?.fields,
                RIGHT,
                priority,
                floatingImage
              )}
            </Fragment>
          ))}
        </div>
      </div>
    ),
    [
      floatingImage,
      headingMediaContainerClasses,
      leftContent,
      leftContentType,
      priority,
      rightContent,
      rightContentType
    ]
  );

  const FiftyFiftyContent = useCallback(
    () => (
      <div className='grid-cols-1 lg:grid-cols-2 justify-items-start lg:grid '>
        <div
          className={cx(
            'grid col-span-1',
            {
              'py-16': leftContentType !== Mediaitem
            },
            customCSS?.parent,
            headingMediaContainerClasses(leftContentType)
          )}
        >
          {leftContent?.map(contentItem => (
            <Fragment key={contentItem?.sys?.contentType?.sys?.id}>
              {contentTypeRenderer(
                contentItem?.sys?.contentType?.sys?.id,
                contentItem?.fields,
                LEFT,
                priority,
                customCSS,
                floatingImage
              )}
            </Fragment>
          ))}
        </div>
        <div
          className={cx(
            'grid col-span-1 grow items-stretch',
            {
              'px-7 lg:px-0 py-12':
                rightContentType !== Mediaitem && rightContentType !== Form
            },
            {
              'md:px-9 lg:px-0': rightContentType === Form
            },
            customCSS?.parent,
            headingMediaContainerClasses(rightContentType)
          )}
        >
          {rightContent?.map(contentItem => (
            <Fragment key={contentItem?.sys?.contentType?.sys?.id}>
              {contentTypeRenderer(
                contentItem?.sys?.contentType?.sys?.id,
                contentItem?.fields,
                RIGHT,
                priority,
                customCSS,
                floatingImage
              )}
            </Fragment>
          ))}
        </div>
      </div>
    ),
    [
      Form,
      Mediaitem,
      customCSS,
      floatingImage,
      headingMediaContainerClasses,
      leftContent,
      leftContentType,
      priority,
      rightContent,
      rightContentType
    ]
  );

  const renderVariant = () => {
    {
      switch (variant) {
        case THIRTYSEVENTY:
          return <SeventyThirtyContent isSeventy={false} />;
        case SEVENTYTHIRTY:
          return <SeventyThirtyContent />;
        case FIFTYFIFTY:
          return <FiftyFiftyContent />;
        default:
          return null;
      }
    }
  };

  // render multiple type of sections
  return (
    <section
      className={sectionClasses}
      data-testid={internalTitle}
      id='custompaddingheadingsubtext'
    >
      {renderVariant()}
    </section>
  );
};

export default memo(SideBySideContent);
