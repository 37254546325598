import { renderToString } from 'react-dom/server';
import { MapPinIcon } from '@heroicons/react/24/solid';
import { DropSvg } from '@components/svgs';
import { getPartnerColorClasses } from '@utils/findPartner';

export const getInfoboxTemplate = content => {
  const { name, distance, address, phone, partnerType } = content;
  return `<section class='flex flex-col bg-[#f5f5f5] p-4 border cursor-text'>
    <div class='text-lg font-ProMedium font-bold'>${name?.toUpperCase()}</div>
    <div class='flex justify-between mt-2.5'>
      <div class='flex uppercase font-bold text-xs'>
        <span class='${getPartnerColorClasses(partnerType)}'>
          ${partnerType ? partnerType : ''}
        </span>
        
      </div>
      <div class='flex font-semibold'>
        <span class='text-xs'>${distance}</span>
      </div>
    </div>
    <div class='mt-[15px] text-xs'>
      <div class='flex flex-row justify-between'>
          <div>${address}</div>
      </div>
      <div class='flex flex-row justify-between'>
            <div class="flex flex-col gap-2">
                <a
                    href=tel:${phone}
                    class='underline underline-offset-4 font-bold tracking-widest cursor-pointer'
                >
                    ${phone ? phone : ''}
                </a>
            </div>
        </div>
      </div>
    </div>
  </section>`;
};
