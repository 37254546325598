import { useRouter } from 'next/router';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { getBgColorClasses } from '@utils/index';
import { availableLocales, HOME, BreadcrumbPaths } from '@constants/index';

/**
 * @class
 *
 * @returns Breadcrumb Component as per the text provided
 */

const Breadcrumb = ({ backgroundColor = 'White', isHeroWithHeading }) => {
  const router = useRouter();
  const linkPath = router.asPath.split('/');
  linkPath.shift();

  const pathArray = linkPath.map((path, i) => {
    return { breadcrumb: path, href: '/' + linkPath.slice(0, i + 1).join('/') };
  });

  const getPath = path => {
    return availableLocales.includes(path) || path === HOME ? 'Home ' : path;
  };

  const showBreadcrumb = () =>
    BreadcrumbPaths.includes(pathArray?.[1]?.breadcrumb) ? null : (
      <div className={cx('pt-20 px-12', getBgColorClasses(backgroundColor))}>
        {pathArray.map((path, index) => (
          <span key={path.breadcrumb} className='capitalize text-xs'>
            {getPath(path.breadcrumb)}{' '}
            {index !== pathArray.length - 1 ? '/ ' : ''}
          </span>
        ))}
      </div>
    );
  return (
    <div data-testid='breadcrumb'>
      {!isHeroWithHeading && pathArray?.[1] ? showBreadcrumb() : null}
    </div>
  );
};
Breadcrumb.propTypes = {
  backgroundColor: PropTypes.string,
  isHeroWithHeading: PropTypes.bool.isRequired
};

export default Breadcrumb;
