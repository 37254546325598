import Accordion from '@components/accordion/index';
import RichText from '@components/richtext';

const InformationModule = ({ header, data }) => {
  return (
    <div style={{ borderBottom: '1px solid #D3D3D3' }}>
      <Accordion
        isActive={true}
        header={header.substring(header.length - 4)}
        svgFlag={true}
        upDownIcons={false}
        isPressRelease={true}
      >
        {data.map(item => (
          <div className='flex flex-col px-1 py-1 pt-3' key={item.id}>
            {item?.fields.cta?.[0].fields.url && (
              <a
                href={item?.fields.cta?.[0].fields.url}
                className='text-[#4171df]'
              >
                {item?.fields.cta?.[0].fields.label}
              </a>
            )}
            <RichText text={item?.fields.informationSection} />
          </div>
        ))}
      </Accordion>
    </div>
  );
};
export default InformationModule;
