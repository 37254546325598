import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import HeadingWithSubText from '@components/headingWithSubText';
import Playlist from '@components/videoGallery/playList';

const VideoGallery = ({ sectionData }) => {
  const videoRef = useRef();
  const { videoHeadingWithSubtext, videos } = sectionData;
  const [currentVideo, setCurrentVideo] = useState(videos?.[0]?.fields);

  const setVideo = video => {
    const videoLink = video?.fields?.video?.fields?.file?.url;
    const thumbnail = video?.fields?.thumbnail?.fields?.file?.url || '';
    if (videoLink && videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      videoRef.current.src = videoLink;
      videoRef.current.poster = thumbnail;
      setCurrentVideo(video.fields);
      videoRef.current.load();
    }
  };

  return (
    <div data-testid='VideoGallery' className='relative'>
      {videoHeadingWithSubtext?.fields && (
        <HeadingWithSubText
          fields={videoHeadingWithSubtext.fields}
          classes={{
            sectionClasses: 'md:!mx-20 md:px-0 md:pt-11',
            holderClasses: 'text-base',
            paragraphCustomClasses: '!mb-3'
          }}
        />
      )}
      <section className='mx-2 justify-center md:flex md:w-auto md:mx-20 relative'>
        <video
          controlsList='nodownload noremoteplayback noplaybackrate'
          disablePictureInPicture
          disableRemotePlayback
          id='playingVideo'
          controls
          className='relative w-full md:w-3/4 lg:mr-10 md:flex-grow md:aspect-video'
          ref={videoRef}
          poster={`https:${currentVideo?.thumbnail?.fields?.file?.url}`}
        >
          {currentVideo && (
            <source
              src={`https:${currentVideo.video.fields.file.url}`}
            ></source>
          )}
        </video>
        {videos?.length > 0 && (
          <div className='hidden text-center w-1/6 overflow-x-auto custom-scrollbar !rounded-none pr-4 lg:block md:aspect-video'>
            <Playlist
              videos={videos}
              setVideo={setVideo}
              currentVideo={currentVideo}
            />
          </div>
        )}
      </section>
      {currentVideo?.headingWithSubtext?.fields && (
        <HeadingWithSubText
          fields={currentVideo.headingWithSubtext.fields}
          classes={{
            sectionClasses: 'md:!mx-20 md:px-0',
            holderClasses: 'text-base'
          }}
        />
      )}
      {videos?.length > 0 && (
        <div className='flex flex-row overflow-x-scroll m-2 md:mx-20 custom-scrollbar !rounded-none lg:hidden'>
          <Playlist
            videos={videos}
            setVideo={setVideo}
            currentVideo={currentVideo}
          />
        </div>
      )}
    </div>
  );
};

VideoGallery.propTypes = {
  sectionData: PropTypes.any
};

export default VideoGallery;
