import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { stopScroll } from '@utils/index';

const Search = ({
  containerClasses = {},
  inputType = 'text',
  placeholderLabel = 'Search Home Energy',
  searchOpen,
  searchCallback,
  onChange,
  ...rest
}) => {
  const [query, setQuery] = useState('');

  useEffect(() => {
    onChange(query);
    let timer = setTimeout(() => {
      searchCallback(query);
    }, 1500);
    return () => clearTimeout(timer);
  }, [query]);

  useEffect(() => {
    if (searchOpen) {
      window.addEventListener('wheel', stopScroll, { passive: false });
      window.addEventListener('touchmove', stopScroll, { passive: false });
      document.body.style.overflow = 'hidden'; // hide scrollbar
    }
    return () => {
      window.removeEventListener('wheel', stopScroll);
      window.removeEventListener('touchmove', stopScroll);
      document.body.style.overflow = 'auto'; // reset scrollbar visibility
    };
  }, [searchOpen]);

  return (
    <form className={containerClasses?.parent} data-testid='search-input'>
      <label
        htmlFor='default-search'
        className='mb-2 text-sm font-medium text-gray-900 sr-only'
      >
        Search
      </label>
      <div className='relative'>
        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
          <svg
            aria-hidden='true'
            className='w-5 h-5 text-gray-500 dark:text-gray-400'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
            />
          </svg>
        </div>
        <input
          type={inputType}
          id='default-search'
          className={cx(
            'block w-full p-4 pl-10 lg:text-3xl text-lg border-b bg-transparent  font-proLight focus:outline-none',
            containerClasses?.input
          )}
          placeholder={placeholderLabel}
          onChange={e => setQuery(e.target.value)}
          {...rest}
        />
      </div>
    </form>
  );
};

export default Search;
