import { getRequest } from './http.interceptor';

export const findADealer = async ({
  address,
  distMiles,
  maxItemPerPage,
  page
}) => {
  return await getRequest(
    `/maps/partners?address=${address}&brand=energy&distMiles=${distMiles}&maxItemPerPage=${maxItemPerPage}&page=${page}`
  );
};
