import { useCallback } from 'react';
import Link from 'next/link';
import cx from 'classnames';
import Button from '@components/button';
import { getCTAVariant } from '@utils/index';

/**
 * @class
 * @param {Array} ctaArray Array of data for CTA
 *
 * @returns CTA Component as per the Array of data
 */

const Cta = ({ ctaArray }) => {
  const renderCta = useCallback(
    cta => (
      <>
        {cta?.fields?.type === 'Button' ? (
          <Button
            variant={getCTAVariant(cta?.fields?.brandColor)}
            key={cta?.fields?.label}
            aria-label={`${cta?.fields?.ariaLabel}`}
            className={`flex justify-center font-BallingerMonoMd w-full py-3 mt-2 ${
              cta?.fields?.brandColor === 'Black' ? 'bg-black text-white' : ''
            }`}
          >
            <span className='flex mt-0.5'>{cta?.fields?.label}</span>
          </Button>
        ) : (
          <span className='underline underline-offset-4 decoration-1'>
            {cta?.fields?.label}
          </span>
        )}
      </>
    ),
    []
  );

  return (
    ctaArray?.length > 0 &&
    ctaArray?.map(cta =>
      cta?.fields?.visible ? (
        <div
          className='w-full lg:inline-flex lg:w-auto pt-4'
          key={cta?.fields?.label}
        >
          <Link href={cta?.fields?.url || ''} passHref={true} legacyBehavior>
            <a
              className={cx(
                'group-hover:text-primary hover:text-primary w-full inline-flex cursor-pointer uppercase tracking-wide'
              )}
              data-testid='CTA'
              target={cta?.fields?.openerType == 'New Tab' ? '_blank' : ''}
              rel='noopener noreferrer'
            >
              {renderCta(cta)}
            </a>
          </Link>
        </div>
      ) : null
    )
  );
};

export default Cta;
