import InputMask from 'react-input-mask';
import Checkbox from '@components/checkbox';
import TextInput from '@components/textInput';
import { formValidation, camalize } from '@utils/index';
import { FormItemTypes } from '@constants/index';

const YES = 'Yes';

const formItemRender = (itemType, itemData, register, errors) => {
  const {
    label,
    placeholder,
    requiredErrorMessage,
    validationErrorMessage,
    // variant,
    value,
    isRequired,
    isDefault
  } = itemData;
  const { Inputtext, Checkboxitem } = FormItemTypes;
  const required = isRequired === YES;
  const inputLabel = itemType === Inputtext && required ? `${label}*` : label;

  const isPhoneNumber =
    typeof label === 'string' && label.toLowerCase().includes('phone');

  switch (itemType) {
    case Inputtext:
      if (isPhoneNumber) {
        return (
          <>
            <div>
              <InputMask
                mask='999-999-9999'
                maskPlaceholder=''
                {...register(camalize(label), {
                  ...formValidation(
                    label?.toLowerCase(),
                    requiredErrorMessage,
                    validationErrorMessage,
                    required
                  ),

                  pattern: {
                    value: /^\d{3}-\d{3}-\d{4}$/,

                    message:
                      validationErrorMessage || 'Please enter a valid number'
                  }
                })}
              >
                {() => (
                  <TextInput
                    error={errors?.['phoneNumber']?.message}
                    name='phoneNumber'
                    label='Phone Number*'
                    type='text'
                    placeholder={placeholder}
                  />
                )}
              </InputMask>
            </div>
          </>
        );
      } else {
        return (
          <TextInput
            type='text'
            label={inputLabel}
            name={label?.toLowerCase()}
            placeholder={placeholder}
            error={errors?.[camalize(label)]?.message}
            {...register(
              camalize(label),
              formValidation(
                label?.toLowerCase(),
                requiredErrorMessage,
                validationErrorMessage,
                required
              )
            )}
          />
        );
      }

    case Checkboxitem:
      return (
        <Checkbox
          name={value}
          classNames={{ label: 'ml-0' }}
          label={label}
          checked={isDefault === YES}
          error={errors?.[value]?.message}
          {...register(
            value,
            formValidation(
              '',
              requiredErrorMessage,
              validationErrorMessage,
              required
            )
          )}
        />
      );

    default:
      return null;
  }
};

export default formItemRender;
