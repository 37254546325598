import React from 'react';
import HeroSectionV2 from '@components/heroV2';
import { HeroSectionTypes, PageContentTypes } from '@constants/index';
import HomePageHeroBanner from './homePageBanner';
import ShortHeroBanner from './shortHeroBanner';

/**
 * @class
 * @param {Object} sectionData Fields Data for section elements
 *
 * @returns Hero Banner Component
 */

const HeroBanner = ({ sectionData, priority }) => {
  const { contentItems, backgroundVideo } = sectionData;

  let key = [];
  if (contentItems?.length) {
    contentItems?.forEach(item => {
      key.push(item?.sys?.contentType?.sys?.id);
    });
  }
  key = key.join('|');
  let type = '';
  if (backgroundVideo) {
    type = HeroSectionTypes.HomePageHeroBanner;
  } else {
    type = HeroBannerMap[key];
  }

  // based on the type, return the appropriate component
  const Component = ContentTypeMap[type] ?? HeroSectionV2;

  if (!Component) {
    return null;
  }

  return <Component sectionData={sectionData} priority={priority} />;
};

const ContentTypeMap = {
  [HeroSectionTypes.ShortHeroBanner]: ShortHeroBanner,
  [HeroSectionTypes.HomePageHeroBanner]: HomePageHeroBanner
};

// key in the format type1|type2|...
const HeroBannerMap = {
  [`${PageContentTypes.HeadingwithSubText}`]: HeroSectionTypes.ShortHeroBanner,
  [`${PageContentTypes.SideBySide}`]: HeroSectionTypes.HomePageHeroBanner
};

export default HeroBanner;
