import { memo } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { PlayIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { isEnterOrSpaceKey } from '@utils/common';

const Playlist = ({ videos = [], setVideo, currentVideo }) => {
  return videos.map(video => {
    const videoThumbnail = video?.fields?.thumbnail?.fields?.file?.url;
    const thumbnailAlt = video?.fields?.thumbnail?.fields?.title;
    const isCurrentVideo =
      currentVideo.internalTitle === video.fields.internalTitle &&
      currentVideo.video.sys.id === video.fields.video.sys.id;

    const handleVideoClick = () => {
      if (!isCurrentVideo) {
        setVideo(video);
      }
    };

    return (
      <div
        className={classNames(
          'mb-1.5 flex relative h-full min-w-[200px] lg:w-auto lg:h-auto mr-5 lg:mr-0 lg:mb-5',
          {
            'cursor-pointer': !isCurrentVideo
          }
        )}
        onClick={handleVideoClick}
        onKeyDown={e => isEnterOrSpaceKey(e) && handleVideoClick()}
        key={video.sys.id}
      >
        <div className='img-container w-full relative'>
          <Image
            src={`https:${videoThumbnail}`}
            objectFit='cover'
            quality={100}
            height={100}
            width={100}
            objectPosition='50% 50%'
            layout='responsive'
            alt={thumbnailAlt}
          />
        </div>
        <div className='absolute grid grid-cols-1 content-center justify-center z-10 h-full w-full text-center'>
          {isCurrentVideo ? (
            <span className='video-playing'></span>
          ) : (
            <span>
              <PlayIcon
                className='text-zinc-100 inline-block'
                height={50}
                width={50}
              />
            </span>
          )}
        </div>
      </div>
    );
  });
};

Playlist.propTypes = {
  videos: PropTypes.array,
  setVideo: PropTypes.func,
  currentVideo: PropTypes.any
};

export default memo(Playlist);
