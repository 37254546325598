import { getAuthToken } from './api/apiService';

const APIM_ENV = process.env.NEXT_PUBLIC_API_BASE_URL;

export const getRequest = async url => {
  const token = await getAuthToken();
  const response = await fetch(APIM_ENV + url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });
  return await response.json();
};

export const postRequest = async (url, body) => {
  const token = await getAuthToken();
  const response = await fetch(APIM_ENV + url, {
    method: 'POST',
    body: body ? JSON.stringify(body) : null,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });
  return await response.json();
};
