import React, { memo, Fragment } from 'react';
import cx from 'classnames';
import useIsMobile from '@components/checkMobile';
import contentTypeRenderer from '@components/contentTypeRenderer';
import { getHeroSectionSize } from '@utils/common';
import { LEFT } from '@constants/index';

/**
 * @class
 * @param {Object} sectionData Data for the hero banner
 *
 * @returns Home Page Banner Component
 */

const HomePageBanner = ({ sectionData, priority }) => {
  const {
    sectionId,
    backgroundImage,
    backgroundVideo,
    contentItems,
    darkeningLayerOpacity,
    heroHeight = []
  } = sectionData || {};
  const heroSectionSize = getHeroSectionSize(heroHeight?.[0]);
  const sectionClasses = cx(
    heroSectionSize,
    'relative',
    'border-b-0',
    '-mb-px',
    'flex',
    'md:bg-auto',
    'w-full',
    'text-left',
    'z-1',
    'lg:!bg-no-repeat lg:!bg-cover',
    '!from-black',
    '!bg-center'
  );

  const url = backgroundVideo?.fields?.asset[0]?.fields?.file?.url;

  const heroImage = backgroundImage?.fields?.asset?.[0]?.fields.file.url;
  const bgUrl = backgroundImage
    ? `https:${backgroundImage?.fields?.asset?.[0]?.fields.file.url}?fm=jpg&fl=progressive`
    : '';
  const opacityRange = heroHeight?.fields?.darkeningLayerOpacity
    ? heroHeight?.fields?.darkeningLayerOpacity
    : darkeningLayerOpacity;
  const isMobile = useIsMobile('heroSection');
  return (
    <>
      {backgroundVideo ? (
        <section>
          <div
            className={cx(
              'bg-cover bg-center bg-no-repeat flex items-center relative w-full py-20 mb-4 h-screen'
            )}
          >
            <>
              <video
                autoPlay={backgroundVideo?.fields?.autoplay ?? true}
                muted
                loop
                playsInline
                preload='metadata'
                // eslint-disable-next-line react/no-unknown-property
                webkit-playsinline='true'
                className='object-cover w-full h-screen '
                poster={`https:${heroImage}`}
              >
                <source src={url}></source>
              </video>
              <div
                className={cx(
                  'flex items-center w-full h-screen absolute pointer-events-none'
                )}
              >
                {contentItems?.map(contentItem => (
                  <Fragment key={contentItem?.sys?.contentType?.sys?.id}>
                    {contentTypeRenderer(
                      contentItem?.sys?.contentType?.sys?.id,
                      contentItem?.fields,
                      LEFT,
                      priority,
                      {
                        containerClasses: 'lg:w-3/5'
                      }
                    )}
                  </Fragment>
                ))}
              </div>
            </>
          </div>
        </section>
      ) : (
        <>
          <section
            style={{
              background: `url(${bgUrl})`
            }}
            data-testid='HomePageHeroBanner'
            className={sectionClasses}
            id={sectionId || ''}
          >
            <div
              style={{
                backgroundColor: `rgba(0, 0, 0, ${opacityRange})`,
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0
              }}
              className={`hidden md:block ${heroSectionSize}`}
            ></div>
            {!isMobile && (
              <div className='hidden lg:block z-40'>
                {contentItems?.map(contentItem => (
                  <div
                    className='z-10'
                    key={contentItem?.sys?.contentType?.sys?.id}
                  >
                    {contentTypeRenderer(
                      contentItem?.sys?.contentType?.sys?.id,
                      contentItem?.fields,
                      LEFT,
                      priority,
                      {
                        containerClasses: 'lg:w-auto'
                      }
                    )}
                  </div>
                ))}
              </div>
            )}
          </section>
          {isMobile && contentItems.length > 0 && (
            <section className='bg-black block lg:hidden'>
              {contentItems?.map(contentItem => (
                <div
                  className='z-10'
                  key={contentItem?.sys?.contentType?.sys?.id}
                >
                  {contentTypeRenderer(
                    contentItem?.sys?.contentType?.sys?.id,
                    contentItem?.fields,
                    LEFT,
                    priority
                  )}
                </div>
              ))}
            </section>
          )}
        </>
      )}
    </>
  );
};

export default memo(HomePageBanner);
