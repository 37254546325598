export const Variants = [
  {
    value: 'Centered 1(width) X 1(count) of 1 Cards',
    type: 'centeredRows',
    cards: 1
  },
  {
    value: 'Centered 1(width) X 2(count) of 2 Cards',
    type: 'centeredRows',
    cards: 2
  },
  {
    value: 'Centered 1(width) X 3(count) of 3 Cards',
    type: 'centeredRows',
    cards: 3
  },
  {
    value: 'Centered 1(width) X 4(count) of 4 Cards',
    type: 'centeredRows',
    cards: 4
  },
  {
    value: 'Promotion Banner 50%-50% full width',
    type: 'promoBanner',
    cards: 1
  },
  {
    value: '1(width) X 3(count) of 3 Cards',
    type: 'normalRow',
    cards: 3
  },
  {
    value: '1(width) X 4(count) of 4 Cards',
    type: 'normalRow',
    cards: 4
  },
  {
    value: '50%-50% of 2 Cards',
    type: 'normalRow',
    cards: 2,
    term: '50-50'
  },
  {
    value: '50%-50% of 3 Cards',
    type: 'normalRow',
    cards: 3,
    term: '50-50'
  },
  {
    value: '50%-50% of 4 Cards',
    type: 'normalRow',
    cards: 4,
    term: '50-50'
  },
  {
    value: '1(width) X 1(count) of 1 Cards',
    type: 'normalRow',
    cards: 1,
    term: '100'
  },
  {
    value: '2(width) X 1(count) of 2 Cards',
    type: 'normalRow',
    cards: 2,
    term: '100'
  },
  {
    value: '3(width) X 1(count) of 3 Cards',
    type: 'normalRow',
    term: '100',
    cards: 3
  },
  {
    value: '4(width) X 1(count) of 4 Cards',
    type: 'normalRow',
    cards: 4,
    term: '100'
  }
];

export const navigationDataFilter = {
  header: 'header',
  footer: 'footer'
};

export const getMonthName = monthNumber => {
  const date = new Date();
  date.setMonth(monthNumber);
  return date.toLocaleString('en-US', { month: 'long' });
};

export const BANNER_BACKGROUND_COLOR = {
  'Energy Blue': 'bg-[#4171DF]',
  'Kohler Blue': 'bg-[#4171DF]',
  Blue: 'bg-[#4171DF]',
  Grey: 'bg-[#555]',
  White: 'bg-[#f1f1f1]',
  Black: 'bg-[#1f2937]'
};
