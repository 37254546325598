import { memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import cx from 'classnames';
import Button from '@components/button';
import RichText from '@components/richtext';
import { getOpenerType } from '@utils/common';

const PromoBanner = ({ fields, position }) => {
  const { image, cta, heading, paragraphText, imagePosition } = fields;
  const banner = {
    cardIcon: image?.fields?.asset?.[0]?.fields?.file?.url,
    copyBlockHeading: heading,
    copyBlockDescription: paragraphText,
    ctaBtn: cta?.fields?.label,
    cardCTALink: cta?.fields?.url,
    mediaUrl: cta?.fields?.mediaUrl?.fields?.file?.url,
    urlType: cta?.fields?.urlType,
    urlOpenerType: cta?.fields?.openerType,
    altText: image?.fields?.asset?.[0]?.fields?.description
  };
  const {
    cardIcon,
    copyBlockDescription,
    copyBlockHeading,
    ctaBtn,
    cardCTALink,
    urlType,
    mediaUrl,
    urlOpenerType
  } = banner;

  const params = {};
  if (cardCTALink?.startsWith('https://')) {
    params.rel = 'noopener noreferrer';
  }

  const onClick = () => {};

  const priority = position >= 0 && position < 2;
  const rightSideImage = imagePosition === 'Right';
  const leftSideImage = imagePosition === 'Left';
  const imageHolderClasses = cx(
    'relative w-full h-96 sm:w-1/2 sm:h-auto',
    {
      'grid order-first md:col-span-1 md:order-first': leftSideImage
    },
    {
      'grid order-first md:col-span-1 sm:order-last': rightSideImage
    }
  );

  return (
    <div className='flex flex-col h-auto sm:flex-row mt-20'>
      <div className={imageHolderClasses}>
        {cardIcon ? (
          <Image
            src={`https:${cardIcon}`}
            blurDataURL={`https:${cardIcon}?fm=avif&q=1`}
            placeholder='blur'
            priority={priority}
            objectPosition='50% 50%'
            objectFit='fill'
            quality={100}
            layout='fill'
            alt=''
          />
        ) : null}
      </div>
      <div className='w-full sm:w-1/2 bg-primary'>
        <div className='p-8 mx-5 lg:p-20'>
          <h2 className='mt-10 mb-0 text-4xl font-normal text-white sm:text-3xl lg:text-6xl'>
            {copyBlockHeading}
          </h2>
          <div className='text-base sm:text-xl w-full sm:w-3/4 text-white font-normal mt-5'>
            <RichText
              text={copyBlockDescription}
              classNames={{
                paragraphCustomClasses: 'my-4'
              }}
            />
          </div>
          <Link
            href={
              urlType === 'Media' ? `https:${mediaUrl}` : cardCTALink || '#'
            }
            passHref={true}
            legacyBehavior
          >
            <a
              {...params}
              target={getOpenerType(urlOpenerType)}
              className='text-black text-base w-full no-underline cursor-pointer group-hover:text-primary hover:text-primary sm:text-xs inline-flex lg:text-base'
              aria-label={`${copyBlockHeading}-${copyBlockDescription}`}
            >
              <Button variant='secondary' className='mt-2' onClick={onClick}>
                {ctaBtn}
              </Button>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default memo(PromoBanner);
