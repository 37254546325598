import { memo } from 'react';
import Image from 'next/image';
import cx from 'classnames';
import PropTypes from 'prop-types';

const MediaItem = ({ image, altText, priority, floatingImage = false }) => {
  return (
    <div
      className={cx('img-container w-full relative', {
        'absolute top-[10%]': floatingImage
      })}
      data-testid='media'
    >
      <Image
        src={`https:${image}`}
        blurDataURL={`https:${image}?fm=avif&q=1`}
        placeholder='blur'
        priority={priority}
        fill
        sizes='(min-width: 768px) 50vw,
                  (min-width: 1200px) 100vw,
                  50vw'
        className='object-cover !h-full !relative'
        alt={altText}
      />
    </div>
  );
};

MediaItem.PropTypes = {
  image: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  priority: PropTypes.bool.isRequired,
  floatingImage: PropTypes.bool
};

export default memo(MediaItem);
