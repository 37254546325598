import { default as FormComponent } from '@components/form';
import HeadingWithSubText from '@components/headingWithSubText';
import MediaItem from '@components/mediaItem';
import Section from '@components/section';
import SideBySideContent from '@components/sideBySideContent';
import { PageContentTypes, RIGHT } from '@constants/index';

/**
 * @method
 * @param {Text} contentType content type to be rendered
 * @param {Object} sectionData  Fields Data for section elements
 * @param {Object} position position of Element on UI
 * @param {Boolean} priority flag for image priority rendering
 *
 * @returns Component as per the passed data
 */

const contentTypeRenderer = (
  contentType,
  data,
  position,
  priority,
  customCSS = {},
  floatingImage = false
) => {
  const {
    Mediaitem,
    HeadingwithSubText,
    InformationModuleSection,
    Form,
    SideBySide
  } = PageContentTypes;
  {
    switch (contentType) {
      case Mediaitem: {
        const asset = data?.asset?.[0]?.fields?.file?.url;
        return (
          <MediaItem
            image={asset}
            altText={data.altText}
            priority={priority}
            floatingImage={floatingImage}
          />
        );
      }
      case HeadingwithSubText:
        return (
          <HeadingWithSubText
            fields={data}
            customCSS={customCSS}
            individualSection={position === RIGHT}
            classes={{
              paragraphCustomClasses: 'max-sm:text-base max-sm:!leading-6',
              ...customCSS
            }}
          />
        );
      case InformationModuleSection:
        return (
          <Section
            sectionData={data}
            sectionType={PageContentTypes.InformationModuleSection}
          />
        );
      case SideBySide:
        return (
          <SideBySideContent
            customCSS={customCSS}
            sectionData={data}
            priority={priority}
          />
        );
      case Form:
        return <FormComponent formData={data} />;
      default:
        return null;
    }
  }
};

export default contentTypeRenderer;
