'use-client';

import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import cx from 'classnames';
import { findADealerAction } from '@redux/actions/findADealer';
import { MapPinIcon } from '@heroicons/react/24/solid';
import { getlocation } from '@services/customer';
import Accordion from '@components/accordion';
import Checkbox from '@components/checkbox';
import Loader from '@components/loader';
import Radio from '@components/radio';
import Search from '@components/search';
import { getPartnerColorClasses, getPartnerType } from '@utils/findPartner';
import { onEnterSpacePress } from '@utils/keyboard';
import { filterOptions, PartnerTypes } from '@constants/findPartner';
import { BingMapsProvider, BingMap } from './bingMap';
import PartnerResultItem from './resultItem';

const RequestQuoteForm = dynamic(() => import('@components/requestQuote'), {
  ssr: false
});
/**
 * Find a partner page component
 */
const FindDealer = () => {
  const location = useSelector(state => state.user?.locationObject);

  const [pushPins, setPushPins] = useState([]);
  const [center, setCenter] = useState([]);
  const [userLocation, setUserLocation] = useState([]);
  const [requestQuote, setRequestQuote] = useState(false);
  const [dealerId, setDealerId] = useState(null);
  const [leadOwnerId, setLeadOwnerId] = useState(null);
  const [dealerName, setDealerName] = useState(null);
  const [dealerList, setDealerList] = useState([]);
  const [zipcode, setZipcode] = useState('');
  const [resultsLoading, setResultsLoading] = useState(false);
  const [highlightPin, setHighlightPin] = useState();
  const [filters, setFilters] = useState({
    All: true,
    Financing: false,
    Sales: false,
    Service: false,
    Installation: false
  });
  const [sortingOption, setSortingOption] = useState('Tier'); // ['Distance', 'Tier' ]
  const [allDealerList, setAllDealerList] = useState([]);
  const [allPushPins, setAllPushPins] = useState([]);

  const sortDealerListByDistance = (a, b) => {
    if (a.travelDistance > b.travelDistance) return 1;
    if (a.travelDistance < b.travelDistance) return -1;
    return 0;
  };

  const sortByGroupingDealers = dealerList => {
    const dataMap = {
      Titanium: [],
      Platinum: [],
      Gold: [],
      Silver: [],
      Developing: [],
      Blank: []
    };
    dealerList.forEach(dealer => {
      if (dealer.partnerType) dataMap[dealer.partnerType].push(dealer);
      else dataMap.Blank.push(dealer);
    });

    Object.keys(dataMap).forEach(key => {
      dataMap[key].sort(sortDealerListByDistance).sort((a, b) => {
        if (a.isBoldCertified && !b.isBoldCertified) return -1; // Move a to a higher position if it is bold certified and b is not
        if (!a.isBoldCertified && b.isBoldCertified) return 1; // Move b to a higher position if it is bold certified and a is not

        return 0;
      });
    });

    return Object.values(dataMap).reduce((acc, val) => acc.concat(val), []);
  };
  const sortDealerListByTier = (a, b) => {
    const tierList = ['Titanium', 'Platinum', 'Gold', 'Silver', 'Developing'];

    const aTier = tierList.indexOf(a.partnerType);
    const bTier = tierList.indexOf(b.partnerType);

    if (aTier === -1) return 1; // Move a to the bottom if its tier is not found in the list
    if (bTier === -1) return -1; // Move b to the bottom if its tier is not found in the list

    if (aTier > bTier) return 1;
    if (aTier < bTier) return -1;
    return 0;
  };

  const getSortedDealerList = () => {
    if (sortingOption === 'Distance')
      return dealerList.sort(sortDealerListByDistance);
    return sortByGroupingDealers(dealerList.sort(sortDealerListByDistance)); //.sort(sortDealerListByTier);
  };

  const removeDuplicatesAndSort = newList => {
    // first remove all duplicates
    const newObject = {};
    // append to existing results;
    newList.forEach(a => {
      newObject[a.id] = a;
    });
    const all = [];
    for (const key in newObject) {
      all.push(newObject[key]);
    }
    sortByGroupingDealers(all);
    return all;
  };

  const fetchData = async (
    address,
    page = 1,
    newDealerList = [],
    newPushPins = []
  ) => {
    if (!address) {
      return;
    }

    // setting default sort to Tier
    setSortingOption('Tier');
    if (page === 1) {
      setResultsLoading(true);
    }
    const data = await findADealerAction({
      address: /[a-z]/i.test(address.substring(0, 1))
        ? address.substring(0, 3).toUpperCase()
        : address,
      distMiles: 100,
      maxItemPerPage: 25,
      page
    });

    const { totalPages, info } = data;
    if (info?.length > 0) {
      // found match for searched address
      info
        .filter(d => d.isActive !== false)
        .forEach(
          ({
            latitude,
            longitude,
            website,
            postalCode,
            street,
            country,
            name,
            Id,
            sfId,
            sapCustomerNumber,
            travelDistance,
            city,
            state,
            phone,
            accountSupType,
            financingPrograms,
            servicesOffered,
            providedPostalCodes: isBoldCertified,
            partnerHQUsers,
            generatorCertification,
            micrositeUrl
          }) => {
            const acceptsQuotations = partnerHQUsers >= 1;
            const id = sfId || Id;
            const partnerType = getPartnerType(accountSupType);
            const address = `${street ? street + ', ' : ''}${
              city ? city + ', ' : ''
            }${state ? state + ', ' : ''}${country} ${postalCode}`;

            const financingOptions = [];
            // capture all financing options except not offered one
            financingPrograms?.split(';').forEach(option => {
              if (
                option &&
                (option.trim().toLowerCase() === 'other consumer financing' ||
                  option.trim().toLowerCase() ===
                    'green sky consumer financing')
              ) {
                financingOptions.push(option);
              }
            });

            let virtualVisits = false;
            let providesServiceWithoutPO = false;
            let providesInstallationAndService = false;

            // check for virtual visit option inside services offered
            servicesOffered?.split(';').forEach(option => {
              if (option?.trim().toLowerCase() === 'offers virtual visits') {
                virtualVisits = true;
              }
              if (
                option?.trim().toLowerCase() ===
                'provides installation, service & warranty'
              ) {
                providesInstallationAndService = true;
              }
              if (
                option?.trim().toLowerCase() ===
                'provides service regardless of purchase origin'
              ) {
                providesServiceWithoutPO = true;
              }
            });

            // map pins
            newPushPins.push({
              location: [latitude, longitude],
              partnerType,
              id,
              distance: `${Number(travelDistance).toFixed(1)} mi`,
              travelDistance,
              name,
              address,
              phone
            });

            // results panel data
            newDealerList.push({
              id,
              website,
              name,
              address,
              phone,
              distance: `${Number(travelDistance).toFixed(1)} mi`,
              travelDistance,
              partnerType: ['Developing', 'Blank'].includes(partnerType)
                ? null
                : partnerType,
              financingOptions,
              virtualVisits,
              isBoldCertified,
              location: [latitude, longitude],
              accountSupType,
              providesInstallationAndService,
              providesServiceWithoutPO,
              acceptsQuotations,
              sapCustomerNumber,
              generatorCertification,
              micrositeUrl
            });
          }
        );
    } else setResultsLoading(false);

    if (page === 1) {
      // remove duplicates and sort
      const allPins = removeDuplicatesAndSort(newPushPins);
      const allDealers = removeDuplicatesAndSort(newDealerList);
      // replace any existing results
      setPushPins(allPins);
      // set results on results panel
      setDealerList(allDealers);
      setHighlightPin();
      // set center of map to first result
      if (allDealers[0]) {
        setCenter(allDealers[0].location);
      }
      if (page >= totalPages) {
        // setup a backup
        setAllPushPins(allPins);
        setAllDealerList(allDealers);
        setResultsLoading(false);
      }
    }
    if (page < totalPages) {
      return await fetchData(address, page + 1, newDealerList, newPushPins);
    }
    if (page !== 1) {
      const allPins = removeDuplicatesAndSort(newPushPins);
      const allDealers = removeDuplicatesAndSort(newDealerList);

      // replace any existing results
      setPushPins(allPins);
      // set results on results panel
      setDealerList(allDealers);

      // all results are loaded
      setResultsLoading(false);
      // setup a backup
      setAllPushPins(allPins);
      setAllDealerList(allDealers);

      // set center
      if (allDealers[0]) {
        setCenter(allDealers[0].location);
      }
    }
  };

  const setLocationByIP = async () => {
    // this approach used user's IP address based location
    const { latitude, longitude, city, postal } = location || {};
    if (latitude || longitude) {
      setCenter([latitude, longitude]);
      setUserLocation([latitude, longitude]);
      const address = postal || city;
      if (address) {
        setZipcode(address);
        await fetchData(address, 1, [], []);
      }
    }
  };

  const requestQuoteLayover = (dealerId, name, leadOwnerId) => {
    setRequestQuote(!requestQuote);
    setDealerId(dealerId);
    setDealerName(name);
    setLeadOwnerId(leadOwnerId);
  };
  const getAddress = async position => {
    const data = await getlocation(position.coords);
    const response = await data.json();
    if (
      response?.statusCode === 200 &&
      response.resourceSets?.[0]?.resources?.[0]?.address?.postalCode
    ) {
      await fetchData(
        response.resourceSets[0].resources[0].address.postalCode,
        1,
        [],
        []
      );
    }
  };

  const getPosition = position => {
    setCenter([position.coords.latitude, position.coords.longitude]);
    setUserLocation([position.coords.latitude, position.coords.longitude]);
    getAddress(position);
  };

  const getPositionError = e => {
    console.log(e);
    if (e.code === 1) {
      // user's location is blocked, check for IP address
      setLocationByIP();
    }
    setResultsLoading(false);
  };
  const isUserLocationNeeded = true;
  const useLocation = () => {
    setZipcode('');
    if (isUserLocationNeeded) {
      navigator.geolocation.getCurrentPosition(getPosition, getPositionError, {
        enableHighAccuracy: true
      });
    }
  };

  useEffect(() => {
    setTimeout(useLocation, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const resultsContainerRef = useRef(null);
  useEffect(() => {
    if (dealerList.length > 0) {
      resultsContainerRef.current.scrollTop = 0; // Scroll to the top
    }
  }, [dealerList, sortingOption]);

  const handleSearchAction = async event => {
    event.preventDefault();
    event.stopPropagation();
    // reset all filters here
    setFilters({
      All: true,
      Financing: false,
      Sales: false,
      Service: false,
      Installation: false
    });
    return await fetchData(event.target.value, 1, [], []);
  };

  const startSearch = async value => {
    setZipcode(value);
    await fetchData(value, 1, [], []);
  };
  const updateFilteredResults = updatedFilters => {
    let updatePushPins = [];
    let updatedDealerList = [];
    // Here filter results based on checkboxes
    if (updatedFilters.All) {
      updatePushPins = allPushPins;
      updatedDealerList = allDealerList;
    } else {
      // here we need to filter results based on parameters
      allDealerList.forEach(dl => {
        if (
          (updatedFilters.Financing && dl.financingOptions.length > 0) ||
          (updatedFilters.Sales &&
            (['Gold', 'Silver', 'Titanium', 'Platinum'].includes(
              dl.partnerType
            ) ||
              dl.accountSupType === 'Sales and Installation' ||
              dl.accountSupType === 'Sales Only' ||
              dl.accountSupType === 'Sales and Service')) ||
          (updatedFilters.Service &&
            (dl.accountSupType === 'Sales and Service' ||
              dl.providesInstallationAndService ||
              dl.providesServiceWithoutPO)) ||
          (updatedFilters.Installation &&
            (dl.accountSupType === 'Sales and Installation' ||
              dl.providesInstallationAndService))
        ) {
          updatedDealerList.push(dl);
          const matchingPin = allPushPins.find(pin => pin.id === dl.id);
          if (matchingPin) {
            updatePushPins.push(matchingPin);
          }
        }
      });
    }
    // update state variables with filtered results;
    setPushPins(updatePushPins);
    setDealerList(updatedDealerList);
    if (updatedDealerList[0]) {
      setCenter(updatedDealerList[0].location);
    }
  };

  const handleCheckUncheck = (name, checked) => {
    let updatedFilters = {};
    if (!checked) {
      // other item selected
      // here check if all options are selected\
      let allTrue = true;
      for (const key in filters) {
        if (filters[key] && key !== 'All' && key !== name) {
          allTrue = false;
        }
      }
      updatedFilters = {
        ...filters,
        All: allTrue,
        [name]: checked
      };
    } else {
      // other item unselected
      updatedFilters = {
        ...filters,
        All: false,
        [name]: checked
      };
    }
    setHighlightPin();
    setFilters(updatedFilters);
    updateFilteredResults(updatedFilters);
  };

  const router = useRouter();

  return (
    <section
      className={cx('flex flex-col lg:flex-row', {
        'mt-[80px]': router.pathname !== '/en-US/find-a-dealer'
      })}
    >
      <Script
        id='bingMapsLoadedEvent'
        strategy='afterInteractive'
        type='text/javascript'
      >
        {`function bingMapsCallback() { window.bingMapsLoaded = true; }`}
      </Script>
      <Script
        strategy='afterInteractive'
        src={`https://www.bing.com/api/maps/mapcontrol?callback=bingMapsCallback&key=${process.env.NEXT_PUBLIC_BING_MAPS}`}
        type='text/javascript'
      />
      <div className='flex flex-col lg:w-1/2 xl:w-2/5'>
        <div className='flex flex-col bg-neutrals-gray200 mx-[18px] font-proLight px-4 md:px-8 lg:px-2 xl:px-8'>
          <div className=''>
            <h1 className='text-[42px] leading-[46px] font-Roman mt-5'>
              Find a Dealer
            </h1>
            <div className='text-base font-proLight mt-5 -tracking-normal'>
              Search by Zip/Postal Code
            </div>
          </div>

          <div className=''>
            <div className='w-full relative'>
              <Search
                placeholderLabel=''
                containerClasses={{ parent: 'py-4', input: 'p-2 md:text-lg' }}
                onKeyDown={e => e.key === 'Enter' && handleSearchAction(e)}
                searchCallback={startSearch}
                onChange={e => setZipcode(e)}
                value={zipcode}
              />
              {resultsLoading && (
                <Loader
                  variant='page-spinner'
                  className='absolute h-16 w-16 right-3 top-3'
                />
              )}
            </div>
            <div
              className='flex max-w-[135px] uppercase underline underline-offset-2 text-xs font-BallingerMonoMd pb-5 cursor-pointer'
              onClick={useLocation}
              onKeyDown={onEnterSpacePress(useLocation)}
            >
              use my location
            </div>
          </div>
        </div>

        <div className='mx-[18px] lg:mr-[26px] font-proLight'>
          <div className='filters py-0 flex'>
            <Accordion
              classNames={{
                container: '!bg-transparent  !mx-0 w-full',
                content: 'pt-6 px-0',
                header: 'px-3 font-bold !uppercase text-sm font-ProBold'
              }}
              upDownIcons={true}
              header='Sort & Filter'
            >
              <div className='flex flex-1 font-semibold justify-between items-center mb-0 text-greish px-3 text-sm font-ProBold'>
                Sort by:
              </div>
              <div className='flex flex-wrap justify-start'>
                <div className='flex-wrap w-full py-2'>
                  <div className='pt-2 px-3 mb-3'>
                    <Radio
                      id='distance'
                      name='sortingoption'
                      checked={sortingOption === 'Distance'}
                      onChange={() => setSortingOption('Distance')}
                      label='Distance'
                      classNames={{
                        label: 'mr-1 text-sm',
                        input:
                          '!mr-0 relative top-[-2px] align-middle md:top-[-1.5px] checked:accent-black'
                      }}
                    />
                  </div>
                  <div className='pb-2 px-3'>
                    <Radio
                      id='tier'
                      name='sortingoption'
                      checked={sortingOption === 'Tier'}
                      onChange={() => setSortingOption('Tier')}
                      label='Dealer Tier'
                      classNames={{
                        label: 'mr-1 text-sm',
                        input:
                          '!mr-0 relative top-[-2px] align-middle md:top-[-1.5px] checked:accent-black'
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='flex flex-1 font-semibold justify-between items-center mb-0 text-greish px-3 text-sm font-ProBold'>
                Filter by:
              </div>
              <div className='flex flex-wrap justify-start'>
                {filterOptions.map(option => (
                  <div key={option} className='flex w-[120px]'>
                    <Checkbox
                      name={option}
                      classNames={{
                        label: 'mr-1 text-sm',
                        input:
                          '!mr-0 relative top-[-2px] align-middle md:top-[-1.5px]'
                      }}
                      label={option}
                      value={filters[option]}
                      onChange={e =>
                        handleCheckUncheck(option, e.target.checked)
                      }
                    />
                  </div>
                ))}
              </div>
            </Accordion>
          </div>
          <div
            ref={resultsContainerRef}
            className='relative z-0 max-h-[540px] lg:h-[540px] overflow-y-auto overflow-x-hidden find-partner-results'
            onMouseLeave={() => setHighlightPin()}
          >
            {!resultsLoading && !dealerList.length && (
              <>
                <p>
                  No results found. Please contact{' '}
                  <a
                    href='mailto:kohlergeneratorsales@kohler.com'
                    className='underline text-blue-500'
                  >
                    kohlergeneratorsales@kohler.com
                  </a>{' '}
                  to get personalized assistance to connect you with a Dealer.
                </p>
                <script>
                  {`
                 window.dataLayer = window.dataLayer || [];
                 window.dataLayer.push({
                   'event': 'dealerLocatorNoResult',
                   'zipCodeSearched': '${zipcode}'
                 });
               `}
                </script>
              </>
            )}
            {getSortedDealerList().map((partner, index) => (
              <div
                key={partner.id}
                onMouseEnter={() => setHighlightPin(partner.id)}
                onFocus={() => setHighlightPin(partner.id)}
                onBlur={() => setHighlightPin()}
                onClick={() => setCenter(partner.location)}
                onKeyDown={event => {
                  if (event.key === 'Enter' || event.key === 'Space') {
                    setCenter(partner.location);
                  }
                }}
                className={cx('mb-5 cursor-pointer border-2 border-solid', {
                  'border-primary': highlightPin && highlightPin === partner.id,
                  'border-white': highlightPin !== partner.id
                })}
              >
                <PartnerResultItem
                  {...partner}
                  website={partner?.micrositeUrl}
                  leadOwnerId={partner?.sapCustomerNumber}
                  position={index}
                  length={dealerList.length}
                  requestQuoteLayover={requestQuoteLayover}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='flex lg:w-1/2 xl:w-2/3 items-center lg:p-0 h-96 lg:h-auto'>
        <div className='flex flex-col w-full h-full mx-auto'>
          <BingMapsProvider>
            <BingMap
              height='auto'
              width='auto'
              center={center}
              userLocation={userLocation}
              pushPins={pushPins}
              highlightPin={highlightPin}
              legend={
                <div className='mx-auto py-5 lg:flex flex-col md:flex-row grid grid-cols-2'>
                  {PartnerTypes.map(partner => (
                    <div
                      key={partner.partnerType}
                      className='flex flex-row lg:pb-0 pb-3'
                    >
                      <MapPinIcon
                        height={20}
                        className={cx(
                          getPartnerColorClasses(partner.partnerType),
                          'mr-1'
                        )}
                      />
                      <span className='text-sm mr-6'>
                        {partner.partnerType} Dealer
                      </span>
                    </div>
                  ))}
                </div>
              }
            />
          </BingMapsProvider>
          {resultsLoading && (
            <Loader
              variant='page-spinner'
              className='absolute h-16 w-16 right-3 bottom-10'
            />
          )}
        </div>
      </div>
      {requestQuote ? (
        <RequestQuoteForm
          dealerId={dealerId}
          leadOwnerId={leadOwnerId}
          dealerName={dealerName}
          open={requestQuote}
          onClose={requestQuoteLayover}
        />
      ) : null}
    </section>
  );
};

export default React.memo(FindDealer);
